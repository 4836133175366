import { useEffect, useRef, useState } from "react";
import * as Spotify from 'newyear_shared/types/spotify';

import Song, { LoadingSong } from "./Song";
import './SongList.scss'

export default function SongList() {
    const [ tracks, setTracks ] = useState<Spotify.SpotifyTrack[] | undefined>();
    const loaded = useRef<boolean>(false);

    useEffect(() => {
        if (!loaded.current) {
            (async () => {
                const res = await fetch(`/api/scenes/music_bingo/tracks`);
                const json = await res.json() as Spotify.SpotifyTrack[];
                setTracks(json);
            })();
            loaded.current = true;
        }
    });

    return (
        <div className="songlist">{
            !tracks
                ? new Array(50).fill(<LoadingSong />)
                : (
                    tracks.length <= 0
                        ? <p className="small">Clara, du skal tilføje nogle sange til playlisten først 🤦‍♂️</p>
                        : tracks.map((track, i) => <Song key={i} track={track} />)
                )
        }</div>
    );
}