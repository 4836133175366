import { useEffect, useRef, useState } from "react";

import './Timer.scss';

export default function Timer(widget: {
    seekingTime: number,
    prefix?: string
}) {
    const timer = useRef<NodeJS.Timer>();
    const [str, setStr] = useState<String>(getTimeStr());

    useEffect(() => {
        timer.current = setInterval(() => setStr(getTimeStr()), 1);
        return () => {
            clearInterval(timer.current);
        };
    });

    function getTimeStr(): string {
        const now = new Date().getTime();
        const diff = widget.seekingTime - now;

        return ''
            + `${Math.floor(diff / 1000 / 60 / 60 / 24).toString().padStart(3, '0')}D `
            + `${Math.floor((diff / 1000 / 60 / 60) % 24).toString().padStart(2, '0')}T `
            + `${Math.floor((diff / 1000 / 60) % 60).toString().padStart(2, '0')}M `
            + `${Math.floor((diff / 1000) % 60).toString().padStart(2, '0')}S `
        ;
    }

    return (
        <div className="timer">
            { widget.prefix ? <p id="prefix">{widget.prefix}</p> : null}
            <h1>{str}</h1>
        </div>
    );
}