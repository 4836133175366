import * as Spotify from 'newyear_shared/types/spotify';

export default function Song(widget: { track: Spotify.SpotifyTrack }) {
    return (
        <div className="song">
            <img src={widget.track.album.images[0].url} alt="cover" id="cover" />
            <div className="details">
                <p id="title">{widget.track.name}</p>
                <div className="artists_container">
                    <p id="artists">{widget.track.artists.map(artist => artist.name).join(', ')}</p>
                </div>
            </div>
        </div>
    );
}

export function LoadingSong() {
    return (
        <div className="song loading">
            <div className='loading-indicator' id="cover" />
            <div className="details">
                <div className='loading-indicator' id="title"></div>
                <div className='loading-indicator' id="artists"></div>
            </div>
        </div>
    );
}