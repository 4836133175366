import SongList from "./widgets/SongList";
import Timer from "../../widgets/Timer";

import './music_bingo.scss';

export default function MusicBingo() {
    return (
        <div className="music_bingo">
            <Timer
                seekingTime={new Date(2024, 11, 31, 19, 0, 0).getTime()}
                prefix="Bingo starter om"
            />
            <SongList />
        </div>
    );
}