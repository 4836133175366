import type * as Spotify from 'newyear_shared/types/spotify';
import { useEffect, useRef, useState } from "react";

import './MusicShower.scss';
import { socket } from "../../../socket";
import { SocketEvents, TVStates } from 'newyear_shared';

export default function MusicShower(props: React.HTMLAttributes<HTMLDivElement>) {
    const [playback, setPlayback] = useState<Spotify.SpotifyPlaybackState>();
    const [progress, setProgress] = useState<number>();
    const trackStopTime = useRef<number>(-1);
    const trackDuration = useRef<number>(-1);
    const trackPaused = useRef<boolean>(true);
    const interval = useRef<NodeJS.Timer[]>([]);
    const loaded = useRef<boolean>(false);

    useEffect(() => {
        if (!loaded.current) {
            updatePlayback();
            loaded.current = true;
        }

        interval.current.push(setInterval(updatePlayback, 10000));
        interval.current.push(setInterval(updateProgressBar, 1));

        socket.on(SocketEvents.tvSateChange, onTVStateChange);

        return () => {
            socket.off(SocketEvents.tvSateChange, onTVStateChange);
            interval.current.forEach(timer => clearInterval(timer));
            interval.current = [];
        };
    // eslint-disable-next-line
    }, []);

    function onTVStateChange(state: TVStates) {
        updatePlayback();
    }

    function updateProgressBar() {
        if (trackDuration.current < 0 || trackStopTime.current < 0) return setProgress(0);
        if (trackPaused.current) return;
        const now = new Date().getTime();
        
        const timeLeft = trackStopTime.current - now;
        if (timeLeft <= 0) {
            trackStopTime.current = -1;
            updatePlayback();
            return;
        }

        setProgress(100 - (timeLeft / trackDuration.current * 100));
    }

    function updatePlayback() {
        (async () => {
            const res = await fetch(`/api/scenes/tv/playback`);

            try {
                const json = await res.json() as Spotify.SpotifyPlaybackState & Spotify.SpotifyError;
                if (json.error || json.item.type !== 'track') return;
    
                setPlayback(json);
                trackStopTime.current = new Date().getTime() + (json.item.duration_ms - json.progress_ms);
                trackDuration.current = json.item.duration_ms;
                trackPaused.current = !json.is_playing;
            } catch (error) {
                /* Not modified */
            }
        })();
    }

    return (
        <div {...props} className={["music_shower", props.className, !playback?.item ? 'loading' : null].filter(e => !!e).join(' ')}>
            <div className={["cover_container", !playback ? 'loading-indicator' : null].filter(e => !!e).join(' ')}>
                {playback && playback.item.type === 'track' ? <img src={playback.item.album.images[0].url} alt="Cover" id="cover" /> : null}
            </div>
            {playback && playback.item.type === 'track' ? <p id="title">{playback.item.name}</p> : <div className="loading-indicator" id="title"></div>}
            {playback && playback.item.type === 'track' ? <p id="artists">{playback.item.artists.map(e => e.name).join(', ')}</p> : <div className="loading-indicator" id="artists"></div>}
            <div className="progress_bar">
                <div className="progress" style={{width: `${progress}%`}}></div>
            </div>
        </div>
    );
}