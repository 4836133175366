import { Route, Routes } from 'react-router-dom';
import MusicBingo from './pages/music_bingo/music_bingo';
import Home from './pages/home/home';
import TV from './pages/tv/tv';
import Controller from './pages/controller/controller';

import './App.scss';

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/bingo' element={<MusicBingo />} />
                <Route path='/tv' element={<TV />} />
                <Route path='/controller' element={<Controller />} />
            </Routes>
        </div>
    );
}

export default App;
