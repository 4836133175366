import Timer from "../../widgets/Timer";
import { FaChessBoard } from 'react-icons/fa6';

import './home.scss'
import AppWidget from "./widget/app_widget";

export default function Home() {
    return (
        <div className="home">
            <Timer
                seekingTime={new Date(2024, 11, 31, 23, 59, 59).getTime()}
                prefix="2025 starter om"
            />
            <div className="content">
                <p className="head">Nytår apps</p>
                <div className="app_list">
                    <AppWidget
                        title="Musik Bingo"
                        icon={FaChessBoard}
                        path="/bingo"
                    />
                </div>
            </div>
        </div>
    );
}