import './BingoScene.scss';

export default function BingoScene(props: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div {...props} className={["bingo", props.className].filter(e => !!e).join(' ')}>
            <p id='title'>Afspillede sange</p>
            <div className="played_tracks">

            </div>
        </div>
    );
}