import ReactHlsPlayer from "react-hls-player";
import Timer from "../../widgets/Timer";
import MusicShower from "./widgets/MusicShower";
import Plan from "./widgets/plan";
import { createRef, useEffect, useState } from "react";
import { socket } from "../../socket";

import './tv.scss';
import { SocketEvents, TVStates } from "newyear_shared";
import BingoScene from "./widgets/BingoScene";

export default function TV() {
    const playerRef = createRef<HTMLVideoElement>();
    const [tvState, setTvState] = useState<TVStates>(TVStates.music);

    useEffect(() => {
        socket.on(SocketEvents.tvSateChange, onTvStateChange);

        return () => {
            socket.off(SocketEvents.tvSateChange, onTvStateChange);
        };
    });

    function onTvStateChange(state: TVStates) {
        if (!playerRef.current) return;
        playerRef.current.muted = state !== TVStates.dr;
        playerRef.current.volume = 1;
        setTvState(state);
    }

    return (
        <div className="tv">
            <ReactHlsPlayer
                src="https://drlive01hls.akamaized.net/hls/live/2014185/drlive01/master.m3u8"
                autoPlay={true}
                controls={false}
                muted={true}
                playerRef={playerRef}
                className="dr"
            />
            <div className={["dashboard", ![TVStates.music, TVStates.bingo].includes(tvState) ? 'hidden' : ''].join(' ')}>
                <Timer seekingTime={new Date(2024, 11, 31, 23, 59, 59).getTime()} />
                <Plan />
                <MusicShower className={tvState === TVStates.bingo ? 'bingo' : ''} />
                <BingoScene className={tvState !== TVStates.bingo ? 'hidden' : ''}/>
                {/* { tvState === TVStates.bingo ? <BingoScene /> : null } */}
            </div>
        </div>
    );
}