import { IconType } from 'react-icons';
import { useNavigate } from 'react-router-dom';

import './app_widget.scss';

export default function AppWidget(widget: {
    title: string
    icon: IconType
    path: string
}) {
    const navigate = useNavigate();

    return (
        <div className="app_widget" onClick={() => navigate(widget.path)}>
            <div className="icon_container">
                {<widget.icon id='icon' />}
            </div>
            <p id="title">{widget.title}</p>
        </div>
    );
}