import { useEffect, useState } from "react";
import { socket } from "../../socket";
import * as FA from 'react-icons/fa';

import './controller.scss';
import { SocketEvents, TVStates } from "newyear_shared";

export default function Controller() {
    const [state, setState] = useState<TVStates>(TVStates.music);

    useEffect(() => {
        socket.on(SocketEvents.tvSateChange, onStateChange);
        
        return () => {
            socket.off(SocketEvents.tvSateChange, onStateChange);
        }
    });

    function onStateChange(state: TVStates) {
        setState(state);
    }

    return (
        <div className="controller">
            <h1>Controlls</h1>
            <div className="btn_list">
                <SourceButton
                    imgSrc="https://play-lh.googleusercontent.com/ob7jPvD-CrPNq8ZlCFRsJ3w3u3_uhJUZW0BL8JCaCIl-ARauuZhbbyK74PERSjdq5tQ=w240-h480-rw"
                    state={TVStates.dr}
                    currentState={state}
                />
                <SourceButton
                    imgSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/7/71/Spotify.png/1024px-Spotify.png"
                    state={TVStates.music}
                    currentState={state}
                />
                <SourceButton
                    imgSrc="https://nyctrivialeague.com/wp-content/uploads/2017/11/Bingo-Icon.png"
                    state={TVStates.bingo}
                    currentState={state}
                />
            </div>
        </div>
    );
}

function SourceButton(props: {
    imgSrc: string
    state: TVStates
    currentState: TVStates
}) {
    return (
        <div className="btn" onClick={() => socket.emit(SocketEvents.tvSateChange, props.state)}>
            <img src={props.imgSrc} alt="cover" id="cover" />
            <div className="state">{
                props.state === props.currentState ? <FA.FaPause id="icon" /> : <FA.FaPlay id="icon" />
            }</div>
        </div>
    );
}